.form-modal-content{
background-color: transparent;
border: 0;
}

.form-modal{
max-width: 600px;
}

.tables-modal{
max-width: 800px;
height: 92%;
}
.tables-modal-content{
    background-color: transparent;
    border: 0;
    height: 100%;
    overflow: auto!important;
}

/* removes the max width for bootstrap modals */
/* .modal-dialog{
max-width: 999999px;
} */

.modal-body{
display: flex;
align-items: center;
justify-content: top;
flex-direction: column;
align-items: stretch;
padding: 0;
/* border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px; */
}

.modal-header{
background-color: white;
/* border-top-left-radius: 8px;
border-top-right-radius: 8px; */
}

.modal-content{
border-radius: 8px;
overflow: hidden;
}