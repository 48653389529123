
@import url('https://fonts.googleapis.com/css?family=Passion+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.icon-button:focus{
  outline: none;
  /* border: none; */
}

body{
  background-color: #111;
}

.image-container{
  /* box-shadow: 0 1px 2px 0 rgba(0,0,0,.1), 12px 0 48px 0 rgba(0,0,0,.16); */
}

.size10{
  font-size: 100px;
}
.size5{
  font-size: 32px;
}
.size3{
  font-size: 24px;
}
.size1{
  font-size: 12px;
}


.mt5{
  margin-top: 32px;
}
.mt3{
  margin-top: 16px;
}
.mt-260{
  margin-top: 260px;
}
.mt-200{
  margin-top: 200px;
}
.mt-100{
  margin-top: 100px;
}

.p8{
  padding: 64px;
}
.p5{
  padding: 32px;
}

.careerline{
  width: 60px;
}


@media(max-width: 480px){
  .careerline{
    width: 24px;
  }

  .size10{
    font-size: 36px;
  }
  .size5{
    font-size: 20px;
  }
  .size3{
    font-size: 18px;
  }
  .size1{
    font-size: 10px;
  }

  .mt5{
    margin-top: 24px;
  }
  .mt3{
    margin-top: 16px;
  }
  .mt-260{
    margin-top: 100px;
  }
  .mt-200{
    margin-top: 80px;
  }
  .mt-100{
    margin-top: 40px;
  }

  .p8{
    padding: 32px;
  }
  .p5{
    padding: 20px;
  }
}

.maxwidth-800{
  max-width: 800px;
}

@media(max-width: 900px){
  .maxwidth-800{
    max-width: 100%;
  }
}

