
@font-face {
    font-family: 'HappyMonkey';
    src: local('HappyMonkey-Regular'), url(../fonts/HappyMonkey-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Baloo2';
    src: url(../fonts/Baloo2-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Baloo2';
    src: url(../fonts/Baloo2-Bold.ttf) format('truetype');
    font-weight: bold;
}


@font-face {
    font-family: 'BellotaText';
    src: url(../fonts/BellotaText-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'BellotaText';
    src: url(../fonts/BellotaText-Bold.ttf) format('truetype');
    font-weight: bold;
}


@font-face {
    font-family: 'ArimaMadurai';
    src: url(../fonts/ArimaMadurai-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'ArimaMadurai';
    src: url(../fonts/ArimaMadurai-Bold.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'Share';
    src: url(../fonts/Share-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Share';
    src: url(../fonts/Share-Bold.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'ComicNeue';
    src: url(../fonts/ComicNeue-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'ComicNeue';
    src: url(../fonts/ComicNeue-Bold.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'Overlock';
    src: url(../fonts/Overlock-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Overlock';
    src: url(../fonts/Overlock-Bold.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'Mali';
    src: url(../fonts/Mali-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Mali';
    src: url(../fonts/Mali-Bold.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'Comfortaa';
    src: url(../fonts/Comfortaa-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Comfortaa';
    src: url(../fonts/Comfortaa-Bold.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'OpenSans';
    src: url(../fonts/OpenSans-Regular.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url(../fonts/OpenSans-Bold.ttf) format('truetype');
    font-weight: bold;
}





@font-face {
    font-family: 'Al-Qalam-Quran';
    src: url(../fonts/Al-Qalam-Quran.ttf) format('truetype');
}




@font-face {
    font-family: 'me_quran';
    src: url(../fonts/me_quran.ttf) format('truetype');
}



@font-face {
    font-family: 'Scheherazade-Bold';
    src: url(../fonts/Scheherazade-Bold.ttf) format('truetype');
}



@font-face {
    font-family: 'Tajawal-Regular';
    src: url(../fonts/Tajawal-Regular.ttf) format('truetype');
}




@font-face {
    font-family: 'MonumentExtended-Regular';
    src: url(../fonts/MonumentExtended-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'MonumentExtended-Ultrabold';
    src: url(../fonts/MonumentExtended-Ultrabold.otf) format('opentype');
}

