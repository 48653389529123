@import url(https://fonts.googleapis.com/css?family=Passion+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inconsolata&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);

/* fade */

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: all 250ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: all 250ms;
}


/* translate */

.translate-exit {
    -webkit-transform: translateX(0);
            transform: translateX(0);

    opacity: 1;
}
.translate-exit-active {
    -webkit-transform: translateX(-30%);
            transform: translateX(-30%);
    transition: all 250ms;

    opacity: 0;
}
.translate-enter {
    -webkit-transform: translateX(30%);
            transform: translateX(30%);

    opacity: 0;
}
.translate-enter-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: all 250ms;

    opacity: 1;
}


/* slide */

.slide-exit {
    -webkit-transform: translateY(0);
            transform: translateY(0);

    opacity: 1;
}
.slide-exit-active {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: all 250ms;

    opacity: 0;
}
.slide-enter {
    opacity: 0;
}
.slide-enter-active {
    opacity: 1;
    transition: all 250ms;
}


.icon-button:focus{
  outline: none;
  /* border: none; */
}

body{
  background-color: #111;
}

.image-container{
  /* box-shadow: 0 1px 2px 0 rgba(0,0,0,.1), 12px 0 48px 0 rgba(0,0,0,.16); */
}

.size10{
  font-size: 100px;
}
.size5{
  font-size: 32px;
}
.size3{
  font-size: 24px;
}
.size1{
  font-size: 12px;
}


.mt5{
  margin-top: 32px;
}
.mt3{
  margin-top: 16px;
}
.mt-260{
  margin-top: 260px;
}
.mt-200{
  margin-top: 200px;
}
.mt-100{
  margin-top: 100px;
}

.p8{
  padding: 64px;
}
.p5{
  padding: 32px;
}

.careerline{
  width: 60px;
}


@media(max-width: 480px){
  .careerline{
    width: 24px;
  }

  .size10{
    font-size: 36px;
  }
  .size5{
    font-size: 20px;
  }
  .size3{
    font-size: 18px;
  }
  .size1{
    font-size: 10px;
  }

  .mt5{
    margin-top: 24px;
  }
  .mt3{
    margin-top: 16px;
  }
  .mt-260{
    margin-top: 100px;
  }
  .mt-200{
    margin-top: 80px;
  }
  .mt-100{
    margin-top: 40px;
  }

  .p8{
    padding: 32px;
  }
  .p5{
    padding: 20px;
  }
}

.maxwidth-800{
  max-width: 800px;
}

@media(max-width: 900px){
  .maxwidth-800{
    max-width: 100%;
  }
}


a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Opera and Firefox */
}

.hideable{
    visibility: hidden;
    opacity:0;
    transition:visibility 0.1s linear,opacity 0.1s linear;
}

.sg-minwidth{
    min-width: 360px;
}

@media(max-width: 460px){
    .sg-minwidth{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        width: 100%;
    }
}

.sg-minwidth-1{
    min-width: 360px;
}

@media(max-width: 460px){
    .sg-minwidth-1{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        width: 100%;
    }
}

.sg-minwidth-2{
    min-width: 480px;
}

@media(max-width: 580px){
    .sg-minwidth-2{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        width: 100%;
    }
}

.sg-minwidth-3{
    min-width: 640px;
}

@media(max-width: 740px){
    .sg-minwidth-3{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        width: 100%;
    }
}

@media(max-width: 500px){
    .collapsible{
        display: none;
    }
}

.sg-border{
    border-width: 0 1px 1px 1px;
    border-color: #ddd;
    border-style: solid;
}
.sg-maxwidth{
    max-width: 1200px;
    margin: 0 auto;
    border-width: 0 1px 1px 1px;
    border-color: #ddd;
    border-style: solid;
}

.sg-modal .sg-maxwidth{
    border-width: 0;
}

@media(max-width: 1200px){
    .sg-maxwidth{
        border-width: 0;
    }
}

.sg-modal{
  top: 5%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  /* display: inline-block; */
  /* margin-top: 5%; */
  min-width: 640px;
  border-radius: 8px;
  overflow: auto;
}

@media(max-width: 640px){
    .sg-modal{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        width: 100%;
    }
}

.sg-modalcontainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.85)
}

.hidescrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hidescrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


p {
    margin: 0;
}

.tooltip-inner{
    white-space: pre-wrap;
    text-align: left;
    z-index: 101;
}



.react-datepicker-popper{
    -webkit-transform: none!important;
            transform: none!important;
    position: static!important;
    will-change: unset!important;
    width: 100%;
}

.Popover{
    z-index: 100!important;
}


td{
    vertical-align: middle!important;
}


input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* don't set body height to 100% otherwise sticky nav won't work */
html, body, #root{
    height: 100%;
    margin: 0;
}

body{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.sg-navitem{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
}

div{
    /* setting this makes some elements overflow easily */
    /* overflow: auto; */
    text-align: left;
    /* flex-direction: column; */
}

p{
    text-align: left;
}


.noscrollbar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.noscrollbar::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.sg-highlight{
    /* border: 3px solid #10aa52; */
    /* background-color: #fafbfc; */
    transition: opacity 100ms, -webkit-transform 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: opacity 100ms, transform 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: opacity 100ms, transform 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: absolute;
}


.sg-rubber {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    display: inline-block;
}
  
.sg-rubber-animating, .sg-rubber-hover:hover {
    /* color: orange; */
    /* animation: rubberBand 5s infinite; */
    -webkit-animation-name: rubberBand;
            animation-name: rubberBand;
}
  
  @-webkit-keyframes rubberBand {
    from {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      -webkit-transform: scale3d(.95, 1.05, 1);
              transform: scale3d(.95, 1.05, 1);
    }
  
    75% {
      -webkit-transform: scale3d(1.05, .95, 1);
              transform: scale3d(1.05, .95, 1);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
}
  
  @keyframes rubberBand {
    from {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      -webkit-transform: scale3d(.95, 1.05, 1);
              transform: scale3d(.95, 1.05, 1);
    }
  
    75% {
      -webkit-transform: scale3d(1.05, .95, 1);
              transform: scale3d(1.05, .95, 1);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
} 



.sg-hoverunderline:hover{
text-decoration: underline;
}

@font-face {
    font-family: 'HappyMonkey';
    src: local('HappyMonkey-Regular'), url(/static/media/HappyMonkey-Regular.331ff69e.ttf) format('truetype');
}

@font-face {
    font-family: 'Baloo2';
    src: url(/static/media/Baloo2-Regular.6208e631.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Baloo2';
    src: url(/static/media/Baloo2-Bold.65719b39.ttf) format('truetype');
    font-weight: bold;
}


@font-face {
    font-family: 'BellotaText';
    src: url(/static/media/BellotaText-Regular.fb9dc9ec.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'BellotaText';
    src: url(/static/media/BellotaText-Bold.898b6935.ttf) format('truetype');
    font-weight: bold;
}


@font-face {
    font-family: 'ArimaMadurai';
    src: url(/static/media/ArimaMadurai-Regular.93c3e707.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'ArimaMadurai';
    src: url(/static/media/ArimaMadurai-Bold.87640c74.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'Share';
    src: url(/static/media/Share-Regular.2b3d8992.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Share';
    src: url(/static/media/Share-Bold.62c36f28.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'ComicNeue';
    src: url(/static/media/ComicNeue-Regular.412e604f.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'ComicNeue';
    src: url(/static/media/ComicNeue-Bold.e4e848b1.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'Overlock';
    src: url(/static/media/Overlock-Regular.de969aa8.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Overlock';
    src: url(/static/media/Overlock-Bold.d8b18329.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'Mali';
    src: url(/static/media/Mali-Regular.129f62a4.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Mali';
    src: url(/static/media/Mali-Bold.834565fb.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'Comfortaa';
    src: url(/static/media/Comfortaa-Regular.99567c98.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Comfortaa';
    src: url(/static/media/Comfortaa-Bold.7d2d5d29.ttf) format('truetype');
    font-weight: bold;
}




@font-face {
    font-family: 'OpenSans';
    src: url(/static/media/OpenSans-Regular.1b0809d5.ttf) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url(/static/media/OpenSans-Bold.dbb97fd9.ttf) format('truetype');
    font-weight: bold;
}





@font-face {
    font-family: 'Al-Qalam-Quran';
    src: url(/static/media/Al-Qalam-Quran.1a79ad23.ttf) format('truetype');
}




@font-face {
    font-family: 'me_quran';
    src: url(/static/media/me_quran.0df26bab.ttf) format('truetype');
}



@font-face {
    font-family: 'Scheherazade-Bold';
    src: url(/static/media/Scheherazade-Bold.b3ce4160.ttf) format('truetype');
}



@font-face {
    font-family: 'Tajawal-Regular';
    src: url(/static/media/Tajawal-Regular.2a157b78.ttf) format('truetype');
}




@font-face {
    font-family: 'MonumentExtended-Regular';
    src: url(/static/media/MonumentExtended-Regular.db74f7a5.otf) format('opentype');
}
@font-face {
    font-family: 'MonumentExtended-Ultrabold';
    src: url(/static/media/MonumentExtended-Ultrabold.9db16afe.otf) format('opentype');
}


.form-modal-content{
background-color: transparent;
border: 0;
}

.form-modal{
max-width: 600px;
}

.tables-modal{
max-width: 800px;
height: 92%;
}
.tables-modal-content{
    background-color: transparent;
    border: 0;
    height: 100%;
    overflow: auto!important;
}

/* removes the max width for bootstrap modals */
/* .modal-dialog{
max-width: 999999px;
} */

.modal-body{
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
        align-items: center;
-webkit-justify-content: top;
        justify-content: top;
-webkit-flex-direction: column;
        flex-direction: column;
-webkit-align-items: stretch;
        align-items: stretch;
padding: 0;
/* border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px; */
}

.modal-header{
background-color: white;
/* border-top-left-radius: 8px;
border-top-right-radius: 8px; */
}

.modal-content{
border-radius: 8px;
overflow: hidden;
}
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

