a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Opera and Firefox */
}

.hideable{
    visibility: hidden;
    opacity:0;
    transition:visibility 0.1s linear,opacity 0.1s linear;
}

.sg-minwidth{
    min-width: 360px;
}

@media(max-width: 460px){
    .sg-minwidth{
        min-width: fit-content;
        width: 100%;
    }
}

.sg-minwidth-1{
    min-width: 360px;
}

@media(max-width: 460px){
    .sg-minwidth-1{
        min-width: fit-content;
        width: 100%;
    }
}

.sg-minwidth-2{
    min-width: 480px;
}

@media(max-width: 580px){
    .sg-minwidth-2{
        min-width: fit-content;
        width: 100%;
    }
}

.sg-minwidth-3{
    min-width: 640px;
}

@media(max-width: 740px){
    .sg-minwidth-3{
        min-width: fit-content;
        width: 100%;
    }
}

@media(max-width: 500px){
    .collapsible{
        display: none;
    }
}

.sg-border{
    border-width: 0 1px 1px 1px;
    border-color: #ddd;
    border-style: solid;
}
.sg-maxwidth{
    max-width: 1200px;
    margin: 0 auto;
    border-width: 0 1px 1px 1px;
    border-color: #ddd;
    border-style: solid;
}

.sg-modal .sg-maxwidth{
    border-width: 0;
}

@media(max-width: 1200px){
    .sg-maxwidth{
        border-width: 0;
    }
}

.sg-modal{
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  /* display: inline-block; */
  /* margin-top: 5%; */
  min-width: 640px;
  border-radius: 8px;
  overflow: auto;
}

@media(max-width: 640px){
    .sg-modal{
        min-width: fit-content;
        width: 100%;
    }
}

.sg-modalcontainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.85)
}

.hidescrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hidescrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


p {
    margin: 0;
}

.tooltip-inner{
    white-space: pre-wrap;
    text-align: left;
    z-index: 101;
}



.react-datepicker-popper{
    transform: none!important;
    position: static!important;
    will-change: unset!important;
    width: 100%;
}

.Popover{
    z-index: 100!important;
}


td{
    vertical-align: middle!important;
}


input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* don't set body height to 100% otherwise sticky nav won't work */
html, body, #root{
    height: 100%;
    margin: 0;
}

body{
    display: flex;
    flex-direction: column;
}

.sg-navitem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

div{
    /* setting this makes some elements overflow easily */
    /* overflow: auto; */
    text-align: left;
    /* flex-direction: column; */
}

p{
    text-align: left;
}


.noscrollbar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.noscrollbar::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.sg-highlight{
    /* border: 3px solid #10aa52; */
    /* background-color: #fafbfc; */
    transition: opacity 100ms, transform 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: absolute;
}


.sg-rubber {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    display: inline-block;
}
  
.sg-rubber-animating, .sg-rubber-hover:hover {
    /* color: orange; */
    /* animation: rubberBand 5s infinite; */
    animation-name: rubberBand;
}
  
  @keyframes rubberBand {
    from {
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      transform: scale3d(.95, 1.05, 1);
    }
  
    75% {
      transform: scale3d(1.05, .95, 1);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
} 



.sg-hoverunderline:hover{
text-decoration: underline;
}