
/* fade */

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: all 250ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: all 250ms;
}


/* translate */

.translate-exit {
    transform: translateX(0);

    opacity: 1;
}
.translate-exit-active {
    transform: translateX(-30%);
    transition: all 250ms;

    opacity: 0;
}
.translate-enter {
    transform: translateX(30%);

    opacity: 0;
}
.translate-enter-active {
    transform: translateX(0);
    transition: all 250ms;

    opacity: 1;
}


/* slide */

.slide-exit {
    transform: translateY(0);

    opacity: 1;
}
.slide-exit-active {
    transform: translateY(-50%);
    transition: all 250ms;

    opacity: 0;
}
.slide-enter {
    opacity: 0;
}
.slide-enter-active {
    opacity: 1;
    transition: all 250ms;
}
